<template>
  <div class="pt-6 pb-12 bg-white">
    <div class="max-w-xl text-center mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <h2 class="sr-only">6 tipů na zdokonalení Vašeho digitálního otiskování</h2>
      <dl class="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
        <div v-for="feature in features" :key="feature.name">
          <dt>
            <!-- <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <component :is="feature.icon" class="h-6 w-6" aria-hidden="true" />
            </div> -->
            <p class="mt-5 text-lg leading-6 font-medium text-red-600">{{ feature.name }}</p>
          </dt>
          <dd class="mt-2 text-base text-gray-600">
            {{ feature.description }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import { GlobeAltIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/vue/outline'

const features = [
  {
    name: 'Zrychlete ošetření',
    description:
      'Staňte se produktivnější. Zkraťte a zpříjemněte pacientům délku ošetření.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Již žádné přeskenovávání',
    description:
      'Jednou a dost! Ušetřete si další návštěvy pacientů. Prostě jednou naskenujte, pošlete data a čekejte na práci.',
    icon: ScaleIcon,
  },
  {
    name: 'Zubní náhrada bez kompromisů',
    description:
      'Kvalitní digitální otisk je začátek. My jej proměníme v maximálně přesnou, funkční a estetickou práci.',
    icon: LightningBoltIcon,
  },
]

export default {
  setup() {
    return {
      features,
    }
  },
}
</script>